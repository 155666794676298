.results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-navigation {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

@media screen and (max-width: 37.5em) {
  .result-navigation {
    width: 80%;
  }
}
